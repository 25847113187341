<template>
  <div class="newCampaign">
    <div class="container">
      <navigation />
      <div class="innerWrapper">
        <div class="row">
          <div class="col-lg-6">
            <!-- Target Section -->
            <campaign-target :campaign="campaign" @getEstimate="getEstimate" />
            <!-- Budget Section -->
            <campaign-budget
              ref="budget"
              :campaign="campaign"
              :newCampaign="newCampaign"
              @getEstimate="getEstimate"
              @submit="createCampaign"
              @continue="openModal"
            />
          </div>
          <div class="col-lg-6">
            <!-- Audience Definition Section -->
            <campaign-audience-defination :campaign="campaign" />
          </div>
        </div>
      </div>
    </div>
    <!-- Instructions -->
    <b-modal ref="my-modal" id="instructions" centered>
      <div class="title">Instruction</div>
      <p class="subTitle">
        What do you want the local Influencers to include in their posts?
      </p>
      <validation-observer ref="observer" v-slot="{ handleSubmit }">
        <b-form @submit.stop.prevent="handleSubmit(submit)">
          <base-input
            name="Title"
            placeholder="Title"
            type="text"
            v-model="newCampaign.name"
            rules="required"
          />
          <base-media-accounts
            rules="required"
            v-model="newCampaign.account_id"
            name="Media Account"
          ></base-media-accounts>
          <base-input
            name="Instagram"
            placeholder="@Our lnstagram"
            type="text"
            v-model="newCampaign.mediaHandle"
            rules="required"
          />
          <base-input
            name="Hast Tag"
            placeholder="#Our hashtag"
            type="text"
            v-model="newCampaign.mediaTags"
            rules="required"
          />
          <base-file-upload
            name="Image"
            v-model="newCampaign.image"
            label="Add an image"
            rules="required"
          ></base-file-upload>

          <base-text-area
            v-model="newCampaign.description"
            placeholder="Ex: I want you to talk about Glowl.me"
            label="Instructions and campaign details for influencers:"
            name="Description"
          ></base-text-area>
          <button
            type="submit"
            class="btn btn-primary"
            :class="processing ? 'processing' : ''"
          >
            continue
          </button>
        </b-form>
      </validation-observer>
    </b-modal>
    <!-- Instructions -->

    <thank-you-modal
      :modal-show="showThankYou"
      :title="thankYouMessage"
    ></thank-you-modal>
  </div>
</template>

<script>
import cloneDeep from "lodash/cloneDeep";
import { mapState, mapActions } from "vuex";
import { CAMPAIGN_ESTIMATE } from "../../graphql/brand/query";
import { CREATE_CAMPAIGN } from "../../graphql/brand/mutations";
import debounce from "lodash/debounce";
import { MESSAGES } from "@/_helpers/notifications";
export default {
  data() {
    return {
      campaign: {},
      estimatefilters: {},
      showThankYou: false,
      thankYouMessage: MESSAGES.CAMPAIGN_CREATED,
      processing: false,
      newCampaign: {
        image: null,
        mediaHandle: null,
        mediaTags: null,
        description: null,
        name: null,
        account_id: [],
        budget: 0,
      },
    };
  },
  components: {
    CampaignTarget: () =>
      import(
        /* webpackChunkName: "campaignTarget" */ "@/components/brand/campaigns/new/CampaignTarget.vue"
      ),
    CampaignBudget: () =>
      import(
        /* webpackChunkName: "compaignBudget" */ "@/components/brand/campaigns/new/CompaignBudget.vue"
      ),
    CampaignAudienceDefination: () =>
      import(
        /* webpackChunkName: "campaignAudienceDefination" */ "@/components/brand/campaigns/new/CompaignAudienceDefination.vue"
      ),
    navigation: () =>
      import(
        /* webpackChunkName: "navigations" */ "@/components/brand/Navigation.vue"
      ),
    ThankYouModal: () =>
      import(
        /* webpackChunkName: "navigations" */ "@/components/common/ThankYouModal.vue"
      ),
  },
  computed: {
    ...mapState({
      filters: (state) => state.campaign.filters,
    }),
  },
  methods: {
    getEstimate: debounce(function () {
      this.estimatefilters = cloneDeep(this.campaign);
      delete this.estimatefilters["__typename"];
      this.estimatefilters.location = this.estimatefilters.location.map(
        (item) => Number(item.id)
      );
      this.estimatefilters.interests = this.estimatefilters.interests.map(
        (id) => Number(id)
      );
      this.estimatefilters.tag = this.estimatefilters.tag.map((item) =>
        Number(item.id)
      );
      //this.estimatefilters.gender = Number(this.estimatefilters.gender);
      this.$apollo
        .query({
          query: CAMPAIGN_ESTIMATE,
          fetchPolicy: "network-only",
          variables: {
            filter: { ...this.estimatefilters },
          },
        })
        .then((data) => {
          if (data) {
            this.$store.commit(
              "campaign/SET_ESTIMATES",
              data.data.campaignEstimate
            );
          }
        })
        .catch((e) => {
          this.handleError(e);
        });
    }),
    createCampaign() {
      this.newCampaign = { ...this.campaign, ...this.newCampaign };
      this.newCampaign.location = this.newCampaign.location.map((item) =>
        Number(item.id)
      );
      this.newCampaign.interests = this.newCampaign.interests.map((id) =>
        Number(id)
      );
      this.newCampaign.gender = this.newCampaign.gender.toString();
      this.$apollo
        .mutate({
          mutation: CREATE_CAMPAIGN,
          variables: {
            account_id: Number(this.newCampaign.account_id.value),
            name: this.newCampaign.name,
            description: this.newCampaign.description,
            influencers: this.newCampaign.influencers,
            followers: this.newCampaign.followers,
            location: this.newCampaign.location,
            category: this.newCampaign.interests,
            budget: this.newCampaign.budget,
            priceStart: this.newCampaign.priceStart,
            priceEnd: this.newCampaign.priceEnd,
            rating: this.newCampaign.rating,
            ageStart: this.newCampaign.ageStart,
            ageEnd: this.newCampaign.ageEnd,
            gender: this.newCampaign.gender,
            posts: this.newCampaign.posts,
            mediaHandle: this.newCampaign.mediaHandle,
            mediaTags: this.newCampaign.mediaTags,
            image: this.newCampaign.image,
            type: "post",
          },
        })
        .then((data) => {
          if (data) {
            if (data.data.createCampaign.state == "success") {
              this.showThankYou = true;
              this.$refs["my-modal"].hide();
              this.resetForm();
              this.processing = false;
            } else {
              this.notify("error", data.data.createCampaign.msg);
            }
          }
        })
        .catch((e) => {
          this.processing = false;
          this.handleError(e);
        });
    },
    openModal() {
      this.$refs["my-modal"].show();
    },
    submit(e) {
      this.processing = true;
      this.createCampaign();
    },
    resetForm() {
      this.newCampaign.image = null;
      this.newCampaign.mediaHandle = null;
      this.newCampaign.mediaTags = null;
      this.newCampaign.description = null;
      this.newCampaign.name = null;
      this.newCampaign.account_id = [];
      this.newCampaign.budget = 0;
    },
  },
  watch: {
    filters: {
      handler() {
        if (this.filters.default) {
          this.campaign = cloneDeep(this.filters.default);
        }
      },
      deep: true,
      immediate: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.newCampaign {
  @media screen and (max-width: 991px) {
    .row {
      > div {
        &:first-child {
          margin-bottom: rem(17px);
        }
      }
    }
  }
}
::v-deep {
  .multiselect {
    border: solid 1px #ccd4e0;
    border-radius: 8px;
  }
  .multiselect__content-wrapper {
    border-color: #ccd4e0;
  }
}
</style>
